import { Box, Grid, Typography } from '@material-ui/core';
import React from 'react';
import Layout from '../../../components/layout';

function CheckoutSuccess() {
  return (
    <Layout>
      <Box mt={20}>
        <Grid my={5} justifyContent='center'>
          <Typography variant='body1' gutterBottom align='center' style={{ margin: 'auto', maxWidth: 720 }}>
            Thank you for registering to attend <b>GMIS 2024</b>, where we will explore the theme of{' '}
            <b>"Resilient by Design: The Next Frontier in Industrial Safety"</b> on <b>5 December 2024</b> at{' '}
            <b>ADNEC, Abu Dhabi</b> Co-located with <b>Abu Dhabi Business Week</b>. We look forward to welcoming you at
            the world’s most-high profile manufacturing & industrialisation summit!
          </Typography>
        </Grid>
      </Box>
    </Layout>
  );
}

export default CheckoutSuccess;
